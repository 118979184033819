import React from 'react'
import SimpleLineIcon from 'react-simple-line-icons'
import logo from '../assets/images/logo.png'
import user from '../assets/images/user.jpg'
import English from '../assets/images/flag/united-states.png'
import Chinese from '../assets/images/flag/china.png'
import Spanish from '../assets/images/flag/spain.png'
import { BrowserRouter as Router, Link, Switch, Route, } from "react-router-dom";


class Header extends React.Component{



    render(){

        return(
            <header className="header-layout">
				<div className="logo-sec"><i className="icon-menu icon" id="barIcon"  onClick={() => this.props.handletoggle()}></i><img src={logo} /></div>

				<div className="header-right">
					<div className="header-icons dropdown mr-5">
						<a href="#" className="language-btn dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img src={English} className="mr-1" /> <span>English</span>
						</a>

						<ul className="dropdown-menu language"  aria-labelledby="navbarDropdown">
				          <a className="dropdown-item" href="#"><img src={English} className="mr-1"/> English</a>
				          <div className="dropdown-divider"></div>
				          <a className="dropdown-item" href="#"><img src={Chinese} className="mr-1"/> Chinese</a>
				          <div className="dropdown-divider"></div>
				          <a className="dropdown-item" href="#"><img src={Spanish} className="mr-1"/> Spanish</a>
				        </ul>
					</div>
					<div className="header-icons dropdown ">
						<a href="#" className="profile-btn dropdown-toggle" id="profile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img src={user} className="mr-1" /> <span>John Deo</span>
						</a>
						<ul className="dropdown-menu language"  aria-labelledby="profile">
				          <a className="dropdown-item" href="#"> My profile</a>
				          <div className="dropdown-divider"></div>
				          <Link className="dropdown-item" to="/"> Logout</Link>
				        </ul>
					</div>
				</div>
				
				

			</header>
        )
    }
}

export default Header