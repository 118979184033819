import React, {Component} from 'react'

class CustomerAdd extends Component{

	handleChange=(e)=>{
		let checked = e.target.checked;
		if(checked) {
		document.getElementById("provideMetal").classList.remove('hide');
		} else {
		document.getElementById("provideMetal").classList.add('hide');
		}
		}
	render(){
		return(
			<React.Fragment>
                <form>
                	
                		
						<div className="row">
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">Name</label>
										<div className="col-md-9">
											<input type="text" className="form-control" />
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">Mobile #</label>
										<div className="col-md-9">
											<input type="text" className="form-control" />
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">Email</label>
										<div className="col-md-9">
											<input type="text" className="form-control" />
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label  className="col-md-3">Address</label>
										<div className="col-md-9">
											<input type="text" className="form-control" />
										</div>
									</div>
								</div>
							{/* <div className="col-md-6">
								<div className="form-group">
									<label>Type of Customer</label>
									<select className="form-control">
										<option>Customer 1</option>
										<option>Customer 2</option>
										<option>Customer 3</option>
										<option>Customer 4</option>
									</select>
								</div>
							</div> */}
							
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">CreditLimit #</label>
										<div className="col-md-9">
											<input type="number" name="creditLimit" min="0" className="form-control" />
										</div>
									</div>
								</div>
								
								<div className="col-md-8">
									<div className="form-group row">
										<label className="col-md-3">Type Of Customer</label>
										<div className="col-md-6">
											<div class="custom-control form-check form-check-inline">
												<input
													id="inline-checkbox1"
													name="inline-checkbox"
													type="radio"
													class="form-check-input custom-control-input form-check-input"
													value="Personal"
												/>
												<label for="inline-checkbox1" class="form-check-label custom-control-label form-check-label">Individual</label>
											</div>
											<div class="custom-control form-check form-check-inline">
												<input
													id="inline-checkbox2"
													name="inline-checkbox"
													type="radio"
													class="form-check-input custom-control-input form-check-input"
													value="Company"
												/>
												<label for="inline-checkbox2" class="form-check-label custom-control-label form-check-label">Company</label>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group row">
										<label  className="col-md-2">Provides Own Metal</label>
										<div className="col-md-9">
											<div>
												<div class="custom-control form-check form-check-inline">
													<input
													id="checkbox1"
													name=""
													type="checkbox"
													class="form-check-input custom-control-input form-check-input"
													value="ProvidesMetal"
													onChange={this.handleChange}
													/>
													<label for="checkbox1" class="form-check-label custom-control-label form-check-label">Provides Metal</label>
												</div>
											</div>
										</div>
										
									</div>
								</div>
								<div className="col-md-12 hide" id="provideMetal">
									<div className="row">
										<div className="col-md-6">
											<div className="form-group row">
												<label className="col-md-3">Material</label>
												<div className="col-md-9">
													<input type="text" name="material" className="form-control" />
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group row">
												<label className="col-md-3">Quantity #</label>
												<div className="col-md-9">
													<input type="text" name="quantity" className="form-control" />
												</div>
												
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group row">
												<label className="col-md-3">Unit</label>
												<div className="col-md-9">
													<select className="form-control">
														<option value="unit">--Unit --</option>
														<option value="cms">Cms</option>
														<option value="point">point</option>
													</select>
												</div>
												
											</div>
										</div>
									</div>
								</div>
							</div>
						
						<div className="col-md-12 mt-4"> 
                            <div className="float-right">
                                <button type="button" className="btn-primary-fill mr-2">Submit</button>
                                <button type="button" className="btn-primary-outline" onClick={() => {this.props.updateLayout('Customer List')}}>Cancel</button>
                            </div>
                        </div>
                	
                </form>
            </React.Fragment>
			)
	}
}

export default CustomerAdd