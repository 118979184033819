import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"

class BranchList extends Component{

	render(){

		this.columns = [
		    
		    {
		      dataField: "name",
		      text: "Branch Name"
		    },
		    {
		      dataField: "address",
		      text: "Branch Address"
		    },
            {
                dataField: "action",
                text: "Action"
            }
		];

		this.customer = [
		  { 
		  	name: "Branch One", 
            address: "Address 1",
            action: <React.Fragment>
                    <button type="button" className="btn btn-sm mr-2" onClick={()=>this.props.handleOrderView('Edit Branch')}><i className="icon-pencil icon"></i></button>
                    <button type="button" className="btn btn-sm btn-info" >Marked Inactive</button>
                </React.Fragment>
		  },
		  {  
            name: "Branch Two", 
            address: "Address 2",
            action: <React.Fragment>
            <button type="button" className="btn btn-sm mr-2" onClick={()=>this.props.handleOrderView('Edit Branch')}><i className="icon-pencil icon"></i></button>
            <button type="button" className="btn btn-sm btn-info">Marked Inactive</button>
        </React.Fragment>
		  },
		  {  
            name: "Branch Three", 
            address: "Address 3",
            action: <React.Fragment>
            <button type="button" className="btn btn-sm mr-2" onClick={()=>this.props.handleOrderView('Edit Branch')}><i className="icon-pencil icon"></i></button>
            <button type="button" className="btn btn-sm btn-info">Marked Inactive</button>
        </React.Fragment>
		  }
		];

		return(
			<React.Fragment>
				<div className="table-responsive">
                	<BootstrapTable keyField="id" data={this.customer} columns={this.columns} />
                	<Pagination className="float-right">
		                <PaginationItem>
		                  <PaginationLink previous tag="button"></PaginationLink>
		                </PaginationItem>
		                <PaginationItem active>
		                  <PaginationLink tag="button">1</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">2</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">3</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">4</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink next tag="button"></PaginationLink>
		                </PaginationItem>
		              </Pagination>
                </div>
	           </React.Fragment>
			)
	}
}

export default BranchList