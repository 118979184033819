import React, {Component} from 'react'
import Select from 'react-select';
const options = [
	{ value: 'Resin Filling- Normal Size', label: 'Resin Filling- Normal Size' },
	{ value: 'Resin Filling- Bigger Size', label: 'Resin Filling- Bigger Size' },
	{ value: 'Mould- Silicon', label: 'Mould- Silicon' },
	{ value: 'Mould- Rubber ', label: 'Mould- Rubber' },
	{ value: 'Casting- Silver', label: 'Casting- Silver' }
  ];
const RepeaterBlock = (props) =>{

	return(
		<div className="row">
			<div className="col-lg-6"> 
				<div className="form-group row">
					<label className="col-md-4">Item</label>
					<div className="col-md-8">
						<select className="form-control">
							<option value="0">-- select--</option>
							<option value="1">Gold </option>
							<option value="2">Silver</option>
							<option value="2">Platinum</option>
							<option value="2">Copper</option>
							<option value="2">Zinc</option>
						</select>
					</div>
					
				</div>
			</div>
			
			<div className="col-lg-6"> 
				<div className="form-group row">
					<label className="col-md-4">Weight</label>
					<div className="col-md-8">
						<input type="text" className="form-control" />
					</div>
					
				</div>
			</div>
			<div className="col-lg-6"> 
				<div className="form-group row">
					<label className="col-md-4">Unit</label>
					<div className="col-md-8">
						<select className="form-control">
							<option value="0">-- unit --</option>
							<option value="1">Pcs.</option>
							<option value="2">Prs.</option>
							<option value="3">Kg.</option>
							<option value="4">Gms.</option>
							<option value="5">Cts.</option>
							<option value="6">Other</option>
							<option value="7">เม็ด</option>
						</select>
					</div>
					
				</div>
			</div>
			<div className="col-lg-6"> 
				<div className="form-group row">
					<label className="col-md-4">Total Cost</label>
					<div className="col-md-8">
						<input type="text" className="form-control" />
					</div>
					
				</div>
			</div>
			<div className="col-md-6" id="processItems"> 
					<div className="form-group row">
						<label className="col-md-4">Add Process</label>
						<div className="col-md-8">
							<Select
								options={options}
								isMulti={true}
							/>
							{/* <select className="form-control">
								<option value="0">-- select process --</option>
								<option value="1">Resin Filling- Normal Size </option>
								<option value="1">Resin Filling- Bigger Size </option>
								<option value="1">Mould- Silicon </option>
								<option value="1">Mould- Rubber </option>
								<option value="1">Casting- Silver </option>
								<option value="1">Casting- Gold </option>
							</select> */}
						</div>
					</div>
				</div>
			{/* <div className="">
				<div className="form-group mt-4">
					<button type="button" className="btn-primary-outline"><i className="icon-close icon"></i></button>
				</div>
			</div> */}
		</div>
		)
}

export default RepeaterBlock