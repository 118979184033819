import React, {Component} from 'react'
import { Row, Col, Card } from 'reactstrap'
import {Link} from 'react-router-dom' 
import Logo from '../assets/images/logo.png'
class Login extends Component{
	render(){
		return(

			<section>
				<div className="container">
					<Row>
						<Col lg="5" md="7" className="mx-auto mt-5">
							<Card className="card-box p-4">
							<div className="card-header text-center"><img src={Logo} className="img-fluid" width="30%" alt="logo"/></div>
								<div className="card-body">
									<form>
										<div className="form-group">
											<label>Username</label>
											<input type="text" name="" className="form-control " placeholder="" />
										</div>
										<div className="form-group">
											<label>Password</label>
											<input type="text" name="" className="form-control " placeholder="" />
										</div>
										<Link to="/main/customers" className="btn-primary-fill d-block text-center mt-5">Submit</Link>
									</form>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</section>

			)
	}
}

export default Login