import React from 'react';
import './App.css';
import {Row, Col, Card, CardBody} from 'reactstrap'
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Login from './pages/Login'
import Main from './layout/Main'




class App extends React.Component{

	


	render(){
	    return(
	    	<React.Fragment>
	    		<Router>

					<Switch>
						<Route exact path="/" component={Login} />
						<Route path="/main" component={Main} />
					</Switch>

				</Router>


	        </React.Fragment>
	       
	     
	    )
	}
}
export default App;
