import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import CustomerAdd from '../components/CustomerAdd'
import CustomerList from '../components/CustomerList'
import ViewCustomerOrder from '../components/ViewCustomerOrder'

class Customers extends Component{

	constructor(props){
    super(props);

    this.state = {
        customerLayout : "Customer List"
        }
    }

    customerList = (props) =>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> Customer List</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Add Customer") }}>  Add Customer</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <CustomerList handleOrderView={this.updateLayout}/>
                </CardBody>
            </Card>
            )
    }

    customerCreate =(props)=>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> Add Customer</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Customer List") }}>  Customer List</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <CustomerAdd updateLayout={this.updateLayout}/>
                </CardBody>
            </Card>
            )
    }

    customerOrderPreview =(props)=>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> View Order list</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Customer List") }}>  Customer List</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <ViewCustomerOrder updateLayout={this.updateLayout}/>
                </CardBody>
            </Card>
            )
    }

    updateLayout = (layout) =>{
        this.setState({customerLayout : layout});
    }

    getCustomerComponent(layout){

        var showLayout = '';

        switch(layout){
            case "Customer List" : showLayout=this.customerList(this.props);
            break;
            case "Add Customer" : showLayout=this.customerCreate(this.props);
            break;
            case "View Customer Order" : showLayout=this.customerOrderPreview(this.props);
            break;
            default:
            showLayout = "";
        }

        return showLayout;

    }

	render(){
		var showLayout = this.getCustomerComponent(this.state.customerLayout)
		return(
			<div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {showLayout}
                    </div>
                </div>
            </div>
			)
	}
}

export default Customers