import React, {Component, useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'


import RepeaterBlock from './Repeater.js'

class InvoiceCreate extends Component{
	constructor(props) {
		super(props);
		this.state={
			rows:[],
			modal: false
		}
	}

	addRow(){
		let rows= [...this.state.rows]
		rows.push(<RepeaterBlock/>)
		this.setState({rows:rows})
	}
	toggleModal = () =>{
		this.setState(prevState => {
			return {
				modal: !prevState.modal
			}
		})
	}
	render(){
		this.columns = [
			{
		    	dataField: "action",
				text: "Action",
				headerStyle: () => {
					return { 
						width: "10%",
						padding: "10px 2px"
					};
				}
		    },
		    {
		      dataField: "id",
			  text: "S. No.",
			  headerStyle: () => {
				return { 
					width: "5%",
					padding: "10px 2px"
				};
			  }
		    },
		    {
		      dataField: "description",
		      text: "Description of Item"
		    },
		    {
		      dataField: "quantity",
		      text: "Item Quantity"
		    },
		    {
		    	dataField: "total",
				text: "Total Amount",
				headerAlign: 'right'
		    }
		    
		];

		this.products = [
			
			{ 
				id: 1,
				description: "506 K. Art(Sommai)",
				quantity: 2,
				total: 700,
				action: <div className="d-flex">
					<a  onClick={()=>this.toggleModal()}><i className="icon-pencil icon mr-2"></i></a>
					<a  ><i className="icon-trash icon"></i></a>
				</div>
			},
			{ 
				action: <div className="d-flex">
					<a  onClick={()=>this.toggleModal()}><i className="icon-pencil icon mr-2"></i></a>
					<a  ><i className="icon-trash icon"></i></a>
				</div>,
				id: 2,
				description: "Silver 925",
				quantity: 2,
				total: 800
				
			},
			{ 
				action: <div className="d-flex">
					<a  onClick={()=>this.toggleModal()}><i className="icon-pencil icon mr-2"></i></a>
					<a  ><i className="icon-trash icon"></i></a>
				</div>,
				id: 3,
				description: "Gold 24K",
				quantity: 2,
				total: 7400
			},
			{ 
				action: '',
				id: '',
				description: "SubTotal",
				quantity: '',
				total: '520'
			},
			{ 
				action: '',
				id: '',
				description: "Vat",
				quantity: '7%',
				total: ''
			},
			{ 
				action: '',
				id: '',
				description: "Total",
				quantity: '',
				total: 5400
			}
			
		];
		return(
			<React.Fragment>
				<form>
					<div className="row">
						<div className="col-md-4">
							<div className="form-group row">
								<label className="col-md-3">Order #</label>
								<div className="col-md-9">
									<select className="form-control">
										<option value="0">-- select order --</option>
										<option value="1">Order 1</option>
										<option value="2">Order 2</option>
										<option value="1">Order 3</option>
										<option value="2">Order 4</option>
									</select>
								</div>
							</div>
						</div>
						<div className="col-md-5">
							<div className="form-group row">
								<label className="col-md-4">Delivery Message</label>
								<div className="col-md-8">
									<input type="text" className="form-control"/>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<button type="button" className="btn-primary-fill mr-1 float-right" onClick={()=>this.toggleModal()}><i className="icon-plus icon mr-1 "></i>Add Invoice Item</button>
							{/* <button type="button" className="btn-primary-fill mr-1 float-right" onClick={()=>this.toggleModal()}><i className="icon-plus icon mr-1 "></i>Open Modal</button> */}
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 container-repeat">
						{this.state.rows}
						</div>
					</div>
				</form>
				<div className="table-responsive invoice-details-table">
					<BootstrapTable keyField="id" data={this.products} columns={this.columns} />
				</div>
				<div className="row">
					<div className="col-md-12">
					<button type="button" className="btn-primary-fill mr-1 float-right">Generate Invoice</button>
					</div>
				</div>
				<ModalExample modal={this.state.modal} toggle={this.toggleModal} />
            </React.Fragment>
			)
	}
}

export default InvoiceCreate

// Add invoice modal

export const ModalExample = (props) => {
	const {modal} = props;
  
	const {toggle} = props;
  
	return (
	  <div>
		<Modal isOpen={modal} toggle={toggle} size="lg">

		  <ModalHeader toggle={toggle}>Add Invoice Item</ModalHeader>

		  <ModalBody>
			<RepeaterBlock/>
		  </ModalBody>
		  <ModalFooter>
			<Button className="btn-primary-fill" onClick={toggle}>Ok</Button>{' '}
			<Button color="secondary" onClick={toggle}>Cancel</Button>
		  </ModalFooter>
		</Modal>
	  </div>
	);
  }