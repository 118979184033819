import React, {Component} from 'react'
import {Row, Col, Card, CardBody, Form, FormGroup, FormText, FormFeedbackForm, Label, InputGroup, Input, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"

class OrderList extends Component{

	buttonFormatter = (cell, row) => {
        console.log(cell, row)
        return (<>
                    <button type="button" className="btn px-1 mr-2" title="Edit"><i className="icon icon-pencil"></i></button>
                    <button type="button" className="btn px-1" title="Delete"><i className="icon icon-trash"></i></button>
                </>
        );
    }
	render(){

		this.columns = [
		    {
		      dataField: "id",
		      text: "Order ID"
		    },
		    {
		      dataField: "name",
		      text: "Customer Name"
		    },
		    {
		      dataField: "image",
		      text: "Product Image"
		    },
		    {
		    	dataField: "remarks",
		    	text: "Remarks"
		    },
		    {
                dataField: "PickUpType",
                text: "Pick Up Type"
            },
            {
                dataField: "DeliveryType",
                text: "Delivery Type"
            },
            {
                dataField: "quantity",
                text: "Quantity",
                headerAlign: 'right',
                align: 'right'
            },
		    {
		    	dataField: "status",
		    	text: "Status"
		    },
		    {
		    	dataField: "action",
				text: "Action",
				formatter: this.buttonFormatter
		    }
		];

		this.products = [
		  { id: 1, 
		  	name: "Item 1", 
		  	image: 100,
		  	remarks: "Lorem ipsem",
			DeliveryType: "" ,
			PickUpType: "",
			quantity: "2",
		  	status: "Created"
		  },
		  { id: 2, 
		  	name: "Item 2", 
		  	image: 102,
		  	remarks: "Lorem ipsem",
			DeliveryType: "" ,
			PickUpType: "",
			quantity: "5",
		  	status: "Delivered"
		  },
		  { id: 3, 
		  	name: "Item 3", 
		  	image: 103,
		  	remarks: "Lorem ipsem",
		  	DeliveryType: "" ,
			PickUpType: "",
			quantity: "1",
		  	status: "Approved"
		  }
		];

		//console.log(this.props.handleEvent)
		return(
				<React.Fragment>
					{/* <Row className="mb-5">
	                  <Col md="2">
	                    <Label >Filter by status:</Label>
	                  </Col>
	                  <Col md="10" className="">
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox1" name="inline-checkbox1" value="option1" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox1">All</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox2" name="inline-checkbox2" value="option2" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox2">Created</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox3" name="inline-checkbox3" value="option3" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox3">Order Confirm</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox4" name="inline-checkbox4" value="option4" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox4">In Process</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox5" name="inline-checkbox5" value="option5" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox5">Finish</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox6" name="inline-checkbox6" value="option6" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox6">Bill Note</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox7" name="inline-checkbox7" value="option7" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox7">Silom</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox8" name="inline-checkbox8" value="option8" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox8">Tanon Tok </Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox9" name="inline-checkbox9" value="option9" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox9">Thapra</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox10" name="inline-checkbox10" value="option10" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox10">On Deelivery</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox11" name="inline-checkbox11" value="option11" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox11">Complete</Label>
	                    </FormGroup>
	                    <FormGroup check inline className="custom-control">
	                      <Input className="form-check-input custom-control-input" type="checkbox" id="inline-checkbox12" name="inline-checkbox12" value="option12" />
	                      <Label className="form-check-label custom-control-label" check htmlFor="inline-checkbox12">Cancel</Label>
	                    </FormGroup>
	                  </Col>
	                </Row> */}
					<div className="row mb-2">
						<div className="offset-md-8 col-md-4">
							
							<input className="form-control" type="text" placeholder="search"/>
							
						</div>
					</div>
					<div className="table-responsive">
	                	<BootstrapTable keyField="id" data={this.products} columns={this.columns} />
	                	<Pagination className="float-right">
			                <PaginationItem>
			                  <PaginationLink previous tag="button"></PaginationLink>
			                </PaginationItem>
			                <PaginationItem active>
			                  <PaginationLink tag="button">1</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink tag="button">2</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink tag="button">3</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink tag="button">4</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink next tag="button"></PaginationLink>
			                </PaginationItem>
			              </Pagination>
	                </div>
	            </React.Fragment>
			)
	}
}

export default OrderList