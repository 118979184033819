import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, CardTitle, CardText} from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class AssignedPackage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			modal: false
		}
	}
	toggleTab = (tab) => {
		if(this.state.activeTab !== tab) {
			this.setState({activeTab: tab})
		}
	}
	toggleModal = () =>{
		this.setState(prevState => {
			return {
				modal: !prevState.modal
			}
		})
	}
	render(){

		this.columns = [
			{
			    dataField: "invoiceId",
			    text: "Invoice ID"
		    },
		    {
			    dataField: "id",
			    text: "Package ID"
		    },
		    {
		        dataField: "name",
		        text: "Package Name"
		    },
		    {
		        dataField: "transfer",
		        text: "Transfer Package"
		    }
		];

		this.products = [
		  { 
			invoiceId: '#001',
		  	id: 1, 
		  	name: 'Order',
		  	// status: <button className="btn-primary-fill"><i className="icon-check icon mr-1"></i>Accept</button>, 
			  // transfer: <select className="form-control"><option>-- select delivery man --</option><option selected>Delivery Man 1</option><option>Delivery Man 2</option><option>Delivery Man 3</option><option>Delivery Man 4</option></select>
			transfer: <React.Fragment>
				<button className="btn btn-primary-fill mr-2" onClick={()=>this.toggleModal()}>Transfer</button>
				<button className="btn btn-success">Deliver</button>
			</React.Fragment>
		  },
		  { 
			invoiceId: '#002',
		  	id: 2, 
		  	name: 'Invoice',
		  	// status: <span className="text-success"><strong>Accepted</strong></span>,
		  	transfer: <React.Fragment>
				<button className="btn btn-primary-fill mr-2" onClick={()=>this.toggleModal()}>Transfer</button>
				<button className="btn btn-success">Deliver</button>
			</React.Fragment>
		  },
		  { 
			invoiceId: '#003',
		  	id: 3,
		  	name: 'Invoice',
		  	// status: "01/15/2020", 
		  	transfer: <React.Fragment>
				<button className="btn btn-primary-fill mr-2" onClick={()=>this.toggleModal()}>Transfer</button>
				<button className="btn btn-success ">Deliver</button>
			</React.Fragment>
		  }
		];
		this.incomingcolumns = [
			{
			    dataField: "invoiceId",
			    text: "Invoice ID"
		    },
		    {
			    dataField: "id",
			    text: "Package ID"
		    },
		    {
		        dataField: "name",
		        text: "Package Name"
		    },
		    {
		        dataField: "action",
		        text: "Action"
		    }
		];

		this.incomingproducts = [
		  { 
			invoiceId: '#001',
		  	id: 1, 
		  	name: 'Order',
			action: <React.Fragment>
				<button className="btn btn-success btn-sm mr-2">Accept</button>
				<button className="btn btn-secondary btn-sm">Reject</button>
			</React.Fragment>
		  },
		  { 
			invoiceId: '#002',
		  	id: 2, 
		  	name: 'Invoice',
		  	action: <React.Fragment>
				<button className="btn btn-success btn-sm mr-2">Accept</button>
				<button className="btn btn-secondary btn-sm">Reject</button>
			</React.Fragment>
		  },
		  { 
			invoiceId: '#003',
		  	id: 3,
		  	name: 'Invoice',
			  action: <React.Fragment>
			  <button className="btn btn-success btn-sm mr-2">Accept</button>
			  <button className="btn btn-secondary btn-sm">Reject</button>
		  </React.Fragment>
		  }
		];
		this.pendingcolumns = [
			{
			    dataField: "invoiceId",
			    text: "Invoice ID"
		    },
		    {
			    dataField: "id",
			    text: "Package ID"
		    },
		    {
		        dataField: "name",
		        text: "Package Name"
			},
			{
		        dataField: "status",
		        text: "Status"
		    },
		    {
		        dataField: "action",
		        text: "Action"
		    }
		];

		this.pendingproducts = [
		  { 
			invoiceId: '#001',
		  	id: 1, 
			name: 'Order',
			status: 'Pending',
			action: <React.Fragment>
				<button className="btn btn-secondary btn-sm">Reject Request</button>
			</React.Fragment>
		  },
		  { 
			invoiceId: '#002',
		  	id: 2, 
			name: 'Invoice',
			status: 'Pending',
		  	action: <React.Fragment>
				<button className="btn btn-secondary btn-sm">Reject Request</button>
			</React.Fragment>
		  },
		  { 
			invoiceId: '#003',
		  	id: 3,
			name: 'Invoice',
			status: 'Pending',
			action: <React.Fragment>
			  <button className="btn btn-secondary btn-sm">Reject Request</button>
		  </React.Fragment>
		  }
		];
		return(
			<React.Fragment>
				<Card className="card-box">
	                <div className="card-header">
	                    <div className="row">
	                        <div className="col-md-12">
	                            <div className="title"> Assigned Packages</div>
	                        </div>
	                    </div>
	                </div>
	                <CardBody>
						<div>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={this.state.activeTab == '1' ? 'active' : ''}
									onClick={() => { this.toggleTab('1'); }}
								>
									Package List 
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={this.state.activeTab == '2' ? 'active' : ''}
									onClick={() => { this.toggleTab('2'); }}
								>
									New incoming packages
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={this.state.activeTab == '3' ? 'active' : ''}
									onClick={() => { this.toggleTab('3'); }}
								>
									Pending outgoing packages
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId="1">
								<div className="table-responsive  mt-4">
									<BootstrapTable keyField="id" data={this.products} columns={this.columns} />
									<Pagination className="float-right">
										<PaginationItem>
										<PaginationLink previous tag="button"></PaginationLink>
										</PaginationItem>
										<PaginationItem active>
										<PaginationLink tag="button">1</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">2</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">3</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">4</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink next tag="button"></PaginationLink>
										</PaginationItem>
									</Pagination>
								</div>
							</TabPane>
							<TabPane tabId="2">
								<div className="table-responsive mt-4">
								<BootstrapTable keyField="id" data={this.incomingproducts} columns={this.incomingcolumns} />
									<Pagination className="float-right">
										<PaginationItem>
										<PaginationLink previous tag="button"></PaginationLink>
										</PaginationItem>
										<PaginationItem active>
										<PaginationLink tag="button">1</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">2</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">3</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">4</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink next tag="button"></PaginationLink>
										</PaginationItem>
									</Pagination>
								</div>
							</TabPane>
							<TabPane tabId="3">
								<div className="table-responsive  mt-4">
									<BootstrapTable keyField="id" data={this.pendingproducts} columns={this.pendingcolumns} />
									<Pagination className="float-right">
										<PaginationItem>
										<PaginationLink previous tag="button"></PaginationLink>
										</PaginationItem>
										<PaginationItem active>
										<PaginationLink tag="button">1</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">2</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">3</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink tag="button">4</PaginationLink>
										</PaginationItem>
										<PaginationItem>
										<PaginationLink next tag="button"></PaginationLink>
										</PaginationItem>
									</Pagination>
								</div>
							</TabPane>
						</TabContent>
						</div>
	                    
	                </CardBody>
	            </Card>
				<ModalExample modal={this.state.modal} toggle={this.toggleModal} />
	        </React.Fragment>
			)
	}
} 

export default AssignedPackage

export const ModalExample = (props) => {
	const {modal} = props;
  
	const {toggle} = props;
  
	return (
	  <div>
		<Modal isOpen={modal} toggle={toggle} size="md">
		  <ModalHeader toggle={toggle}>Transfer Package</ModalHeader>
		  <ModalBody>
			<div className="row">
				<div className="col-md-12">
					 <div className="form-group row">
						 <h5 className="col-md-8"> Invoice #</h5>
					 </div>
					 <div className="form-group row">
						 <label className="col-md-4">Select Delivery Man</label>
						 <div className="col-md-8">
							 <select className="form-control">
								<option>Delivery Man 1</option>
								<option>Delivery Man 2</option>
								<option>Delivery Man 3</option>
								<option>Delivery Man 4</option>
								<option>Delivery Man 5</option>
							 </select>
						 </div>
					 </div>
				</div>
			</div>
		  </ModalBody>
		  <ModalFooter>
			<Button className="btn-primary-fill" onClick={toggle}>Ok</Button>{' '}
			<Button color="secondary" onClick={toggle}>Cancel</Button>
		  </ModalFooter>
		</Modal>
	  </div>
	);
  }