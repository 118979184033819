import React, {Component} from 'react'

class BranchAdd extends Component{

	render(){
		return(
			<React.Fragment>
                <form>
                	<div className="row">
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">Branch Name</label>
								<div className="col-md-9">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">Branch Address</label>
								<div className="col-md-9">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		
                		<div className="col-md-12 mt-4"> 
                            <div className="float-right">
                                <button type="button" className="btn-primary-fill mr-2">Save</button>
                                <button type="button" className="btn-primary-outline" onClick={() => this.props.updateLayout('Branch List')}>Cancel</button>
                            </div>
                        </div>
                	</div>
                </form>
            </React.Fragment>
			)
	}
}

export default BranchAdd