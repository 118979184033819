import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom"
import Header from './Header'
import Sidebar from './Sidebar'
import MainContainer from './MainContainer'

class Main extends React.Component{

	constructor(props){
	super(props);

		this.state ={
			toggleMenu : false
		}
		
	}
	toggleButton = () =>{
		//alert('hello');
		this.setState(prevState => ({toggleMenu : !prevState.toggleMenu}))
	}

	render(){
		return(

			<React.Fragment>
				<Header handletoggle={this.toggleButton}/>
		     	<section> 
					<Sidebar handleClass={this.state.toggleMenu}/>
					<MainContainer handleMainClass={this.state.toggleMenu}/>
				</section>
			</React.Fragment>

			)
	}
} 

export default Main