import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import Gold from '../assets/images/gold.jpg'
import Dimond from '../assets/images/dimond.jpg'

class ViewCustomerOrder extends Component{

	constructor(props) {
		super(props);
		this.state = {
			
            visibility: false
		}
	}

	toggleVisibility = () => {
		console.log(this.state.visibility);
            this.setState({
                visibility: !this.state.visibility
          });
    }
	render(){

		return(
			<React.Fragment>
				<h5><strong>Jon Doe</strong></h5><br/>
				<h6>Email : himanshu.joshi+1@gmail.com</h6>
				<h6>Phone : 7987019839</h6>
				<h6>TotalOrders : 35</h6><br/>
				<button className="btn-primary-fill" onClick={() => {this.props.updateLayout("Add Customer")}}>{'Edit'}</button>
				<button className="btn-primary-fill ml-2 mb-2" onClick={this.toggleVisibility}>{this.state.visibility ? 'Hide Orders': 'View Orders'}</button>
				{this.state.visibility === true ? 
					<div id="accordion">
					<div className="card">
					  <div className="card-header">
						<a className="card-link" data-toggle="collapse" href="#collapseOne">
						  Orderd ID #0321100
						</a>
					  </div>
					  <div id="collapseOne" className="collapse show" data-parent="#accordion">
						<div className="card-body">
						  <div className="row">
							  <div className="col-md-12">
								  <div className="order-content">
									  <h6><strong>Thumbnail of image</strong></h6>
									  <div className="order-image">
										  <div className="img"><img src={Gold}/></div>
										  <div className="img"><img src={Dimond}/></div>
									  </div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Remarks</strong></h6>
									  <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Type (Delivery)</strong></h6>
									  <div>Keas 69 Str. 15234, Chalandri Athens, Greece</div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Status</strong></h6>
									  <div className="text-success"><strong>Approved</strong></div>
								  </div>
							  </div>
						  </div>
						</div>
					  </div>
					</div>
  
					<div className="card">
					  <div className="card-header">
						<a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
						  Orderd ID #0321101
						</a>
					  </div>
					  <div id="collapseTwo" className="collapse" data-parent="#accordion">
						<div className="card-body">
						  <div className="row">
							  <div className="col-md-12">
								  <div className="order-content">
									  <h6><strong>Thumbnail of image</strong></h6>
									  <div className="order-image">
										  <div className="img"><img src={Gold}/></div>
										  <div className="img"><img src={Dimond}/></div>
									  </div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Remarks</strong></h6>
									  <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Type (Delivery)</strong></h6>
									  <div>Keas 69 Str. 15234, Chalandri Athens, Greece</div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Status</strong></h6>
									  <div className="text-success"><strong>Approved</strong></div>
								  </div>
							  </div>
						  </div>
						</div>
					  </div>
					</div>
  
					<div className="card">
					  <div className="card-header">
						<a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
						  Orderd ID #0321103
						</a>
					  </div>
					  <div id="collapseThree" className="collapse" data-parent="#accordion">
						<div className="card-body">
						  <div className="row">
							  <div className="col-md-12">
								  <div className="order-content">
									  <h6><strong>Thumbnail of image</strong></h6>
									  <div className="order-image">
										  <div className="img"><img src={Gold}/></div>
										  <div className="img"><img src={Dimond}/></div>
									  </div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Remarks</strong></h6>
									  <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Type (Delivery)</strong></h6>
									  <div>Keas 69 Str. 15234, Chalandri Athens, Greece</div>
								  </div>
								  <div className="order-content">
									  <h6><strong>Status</strong></h6>
									  <div className="text-success"><strong>Approved</strong></div>
								  </div>
							  </div>
						  </div>
						</div>
					  </div>
					</div>
  
				  </div>: ''
				}
				
			</React.Fragment>		
			)
	}
}

export default ViewCustomerOrder