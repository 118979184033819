import React from 'react'
import SimpleLineIcon from 'react-simple-line-icons'
import {Row, Col, Card, CardBody} from 'reactstrap'
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom"
import Home from '../pages/Home'
import Orders from '../pages/Orders'
import Invoice from '../pages/Invoice'
import AssignedPackage from '../pages/AssignedPackage'
import Customers from '../pages/Customers'
import Users from '../pages/Users'
import Branches from '../pages/Branches'
import Inventory from '../pages/Inventory'
import Wastage from '../pages/Wastage'
import Sales from '../pages/Sales'
import ItemMaster from '../pages/ItemMaster'

export default class MainContainer extends React.Component{
    render(){
        return(
            <div className={this.props.handleMainClass ? "main-container" : "main-container full"} id="main-container">
            	<Switch>
            		<Route exact path="/main" component={Home} />
            		<Route exact path="/main/orders">
            			<Orders />
            		</Route>
                    <Route exact path="/main/invoice" component={Invoice} />
                    <Route exact path="/main/assignedpackage" component={AssignedPackage} />
                    <Route exact path="/main/customers" component={Customers} />
                    <Route exact path="/main/users" component={Users} />
                    <Route exact path="/main/branches" component={Branches} />
                    <Route exact path="/main/reports/inventory" component={Inventory} />
                    <Route exact path="/main/reports/wastage" component={Wastage}/>
                    <Route exact path="/main/reports/sales" component={Sales}/>
                    <Route exact path="/main/itemMaster" component={ItemMaster} />
            	</Switch>
			</div>

        )
    }
}