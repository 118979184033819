import React, {Component} from 'react'
import {Row, Col, Card, CardBody, Form, FormGroup, FormText, FormFeedbackForm, Label, InputGroup, Input, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"

class InvoiceList extends Component{

	buttonFormatter = (cell, row) => {
        return (<>
                    <button type="button" className="btn px-1 mr-2" title="View" onClick={()=>this.props.handleInvoiceView("Invoice Preview")}><i className="icon icon-eye"></i></button>
                </>
        );
    }
	render(){

		this.columns = [
		    {
		      dataField: "id",
		      text: "Invoice ID"
		    },
		    {
		      dataField: "date",
		      text: "Date"
		    },
		    {
		      dataField: "name",
		      text: "Customer Name"
		    },
			{
		    	dataField: "deliveryMessage",
		    	text: "Delivery Message"
		    },
		    {
		    	dataField: "action",
				text: "Action",
				formatter: this.buttonFormatter,
				align: 'center',
				headerAlign: 'center'
		    }
		];

		this.products = [
		  { 
		  	id: 1, 
		  	date: "01/01/2020", 
		  	name: "John Deo",
		  	delivery: '',
		  	action: <a href="#">Logs</a>
		  },
		  { 
		  	id: 2, 
		  	date: "12/01/2019", 
		  	name: "Mark ",
		  	delivery: '',
		  	action: <a href="#">Logs</a>
		  },
		  { 
		  	id: 3,
		  	date: "01/15/2020", 
		  	name: "Jarry ",
		  	delivery: '',
		  	action: <a href="#">Logs</a>
		  }
		];

		//console.log(this.props.handleEvent)
		return(
				<React.Fragment>
					<div className="table-responsive">
	                	<BootstrapTable keyField="id" data={this.products} columns={this.columns} />
	                	<Pagination className="float-right">
			                <PaginationItem>
			                  <PaginationLink previous tag="button"></PaginationLink>
			                </PaginationItem>
			                <PaginationItem active>
			                  <PaginationLink tag="button">1</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink tag="button">2</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink tag="button">3</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink tag="button">4</PaginationLink>
			                </PaginationItem>
			                <PaginationItem>
			                  <PaginationLink next tag="button"></PaginationLink>
			                </PaginationItem>
			              </Pagination>
	                </div>
	            </React.Fragment>
			)
	}
}

export default InvoiceList