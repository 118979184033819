import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"

class UserList extends Component{

	render(){

		this.columns = [
		    
		    {
		      dataField: "name",
		      text: "Name"
		    },
		    {
		      dataField: "email",
		      text: "Email"
		    },
		    {
		    	dataField: "userType",
		    	text: "User Type"
            },
            {
                dataField: "action",
                text: "Action"
            }
		];

		this.customer = [
		  { 
		  	name: "Alison Alsop", 
            email: 'alisonalsop@gmail.com',
            userType: 'Invoicing Admin',
            action: <button type="button" className="btn" onClick={()=>this.props.handleOrderView('Edit User')}><i className="icon-pencil icon"></i></button>
		  },
		  {  
		  	name: "Anna Baker", 
            email: 'Anna@gmail.com',
            userType: 'Delivery Man',
            action: <button type="button" className="btn"><i className="icon-pencil icon"></i></button>
		  },
		  {  
		  	name: "Gordon Slater", 
            email: 'Slatergordon@gmail.com',
            userType: 'Delivery Man',
            action: <button type="button" className="btn"><i className="icon-pencil icon"></i></button>
		  }
		];

		return(
			<React.Fragment>
				<div className="table-responsive">
                	<BootstrapTable keyField="id" data={this.customer} columns={this.columns} />
                	<Pagination className="float-right">
		                <PaginationItem>
		                  <PaginationLink previous tag="button"></PaginationLink>
		                </PaginationItem>
		                <PaginationItem active>
		                  <PaginationLink tag="button">1</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">2</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">3</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">4</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink next tag="button"></PaginationLink>
		                </PaginationItem>
		              </Pagination>
                </div>
	           </React.Fragment>
			)
	}
}

export default UserList