import React, {Component} from 'react'

class ItemAdd extends Component{

	render(){
		return(
			<React.Fragment>
                <form>
                	<div className="row">
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Item Name</label>
								<div className="col-md-7">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                        <div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Item Type</label>
								<div className="col-md-7">
									<select className="form-control">
										<option value="metal">Metal</option>
										<option value="process">Process</option>
									</select>
								</div>
                				
                			</div>
                		</div>
						<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Item Composition</label>
								<div className="col-md-7">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Rate</label>
								<div className="col-md-7">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Unit</label>
								<div className="col-md-7">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Tax Rate</label>
								<div className="col-md-7">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                        <div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Loss Percentage Production</label>
                				<div className="col-md-7">
									<input type="text" className="form-control" />
								</div>
								
                			</div>
                		</div>
                        
                        <div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-5">Loss Percentage Billing </label>
								<div className="col-md-7">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-md-5">Is Taxable</label>
                                <div className="col-md-7">
									<div>
										<div class="custom-control form-check form-check-inline">
											<input id="inline-checkbox1" name="isTaxeble" type="radio" class="form-check-input custom-control-input form-check-input" value="option1"/>
											<label for="inline-checkbox1" class="form-check-label custom-control-label form-check-label">Yes</label>
										</div>
										<div class="custom-control form-check form-check-inline">
											<input id="inline-checkbox2" name="isTaxeble" type="radio" class="form-check-input custom-control-input form-check-input" value="option1"/>
											<label for="inline-checkbox2" class="form-check-label custom-control-label form-check-label">No</label>
										</div>
									</div>
								</div>
                            </div>
                        </div>
                		<div className="col-md-12 mt-4"> 
                            <div className="float-right">
                                <button type="button" className="btn-primary-fill mr-2">Submit</button>
                                <button type="button" className="btn-primary-outline" onClick={() => this.props.updateLayout('Item List')}>Cancel</button>
                            </div>
                        </div>
                	</div>
                </form>
            </React.Fragment>
			)
	}
}

export default ItemAdd