import React, {Component} from 'react'
import BootstrapTable from "react-bootstrap-table-next"
class InvoicePreview extends Component{
	render(){
		this.columns = [
		    {
		      dataField: "id",
			  text: "S. No.",
			  headerStyle: () => {
				return { 
					width: "5%",
					padding: "10px 2px"
				};
			  }
		    },
		    {
		      dataField: "description",
		      text: "Description of Item"
		    },
		    {
		      dataField: "quantity",
		      text: "Item Quantity"
		    },
		    {
		    	dataField: "total",
				text: "Total Amount",
				headerAlign: 'right'
		    }
		    
		];

		this.products = [
			
			{ 
				id: 1,
				description: "506 K. Art(Sommai)",
				quantity: 2,
				total: 700,
				
			},
			{ 
			
				id: 2,
				description: "Silver 925",
				quantity: 2,
				total: 800
				
			},
			{ 
				
				id: 3,
				description: "Gold 24K",
				quantity: 2,
				total: 7400
			},
			{ 
				
				id: '',
				description: "SubTotal",
				quantity: '',
				total: '520'
			},
			{ 
				
				id: '',
				description: "Vat",
				quantity: '7%',
				total: ''
			},
			{ 
				
				id: '',
				description: "Total",
				quantity: '',
				total: 5400
			}
			
		];
		return(
			<>

			<div className="table-responsive invoice-details-table">
				<BootstrapTable keyField="id" data={this.products} columns={this.columns} />
			</div>
			</>
			)
	}
}

export default InvoicePreview