import React, {Component} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import InvoiceList from '../components/InvoiceList'
import InvoiceCreate from '../components/InvoiceCreate'
import InvoicePreview from '../components/InvoicePreview'
import InvoiceLogs from '../components/InvoiceLogs'

class Invoice extends Component{

    constructor(props){
    super(props);

    this.state = {
        hideShow : false,
        invoiceLayout : "Invoice List"
        }
    }

    toggleButton = () =>{
        this.setState(prevState => ({
          hideShow: !prevState.hideShow
        }));
    }

    

    listInvoice = (props) =>{
        return(
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> Invoice List</div>
                        </div>
                            {/* <div className="col-md-6">
                                <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Create Invoice") }}>  Create Invoice</button>
                            </div> */}
                    </div>
                </div>
                <CardBody>
                    <InvoiceList handleInvoiceView={this.updateLayout}/>
                </CardBody>
            </Card>
        )
    }

    // createInvoice = (props) => {
    //     return(
    //         <Card className="card-box">
    //             <div className="card-header">
    //                 <div className="row">
    //                     <div className="col-md-6">
    //                         <div className="title">Create Invoice </div>
    //                     </div>
    //                     <div className="col-md-6">
    //                         <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Invoice List") }}>  Invoice List</button>
    //                     </div>
    //                 </div>
    //             </div>
    //             <CardBody>
    //                 <InvoiceCreate handlePreview={this.previewInvoice("Invoice Preview")}  />
    //             </CardBody>
    //         </Card>
    //     )
    // }

    previewInvoice = (props) =>{
        //alert("hello");
        return(
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">Invoice# 1</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Invoice List") }}>Invoice List</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <InvoicePreview />
                </CardBody>
            </Card>
        )
    }

    logsInvoice = (props) => {
        return(
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> Invoice Logs </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Invoice Logs") }}>  Invoice List</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <InvoiceLogs />
                </CardBody>
            </Card>
        )
    }

    showPreview = () =>{

    }

    updateLayout = (layout) => {
        this.setState({invoiceLayout : layout});
    }

    getInvoiceComponent(layout){

        var showLayout = '';

        switch(layout){
            case "Invoice List" : showLayout=this.listInvoice(this.props);
            break;
            case "Create Invoice" : showLayout=this.createInvoice(this.props);
            break;
            case "Invoice Preview" : showLayout=this.previewInvoice(this.props);
            break;
            case "Invoice Logs" : showLayout=this.logsInvoice(this.props);
            break;
            default:
            showLayout = "";
        }

        return showLayout;

    }


	render(){

        var showLayout = this.getInvoiceComponent(this.state.invoiceLayout)

		return(
			<div className="container-fluid">
                <Row>
                    <Col md="12">
                        {showLayout}
                    </Col>
                </Row>
            </div>
			)
	}
}

export default Invoice