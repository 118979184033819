import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"

class CustomerList extends Component {

	render() {

		this.columns = [

			{
				dataField: "name",
				text: "Name"
			},
			{
				dataField: "totalOrder",
				text: "Total Order #"
			},
			{
				dataField: "view",
				text: "View"
			}
		];

		this.customer = [
			{
				name: "Alison Alsop",
				totalOrder: 10,
				view: <button type="button" className="btn" onClick={() => this.props.handleOrderView('View Customer Order')}><i className="icon-eye icon"></i></button>
			},
			{
				name: "Anna Baker",
				totalOrder: 102,
				view: <button type="button" className="btn"><i className="icon-eye icon"></i></button>
			},
			{
				name: "Gordon Slater",
				totalOrder: 103,
				view: <button type="button" className="btn"><i className="icon-eye icon"></i></button>
			}
		];

		return (
			<React.Fragment>
				<div className="row mb-2">
					<div className="offset-md-8 col-md-4">
						<input className="form-control" type="text" placeholder="search " />
					</div>
				</div>
				<div className="table-responsive">
					<BootstrapTable keyField="id" data={this.customer} columns={this.columns} />
					<Pagination className="float-right">
						<PaginationItem>
							<PaginationLink previous tag="button"></PaginationLink>
						</PaginationItem>
						<PaginationItem active>
							<PaginationLink tag="button">1</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink tag="button">2</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink tag="button">3</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink tag="button">4</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink next tag="button"></PaginationLink>
						</PaginationItem>
					</Pagination>
				</div>
			</React.Fragment>
		)
	}
}

export default CustomerList