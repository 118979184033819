import React from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'

class Home extends React.Component{
    render(){
        return(
            <div className="container-fluid">
                <Row>
                    <Col md="12">
                        <Card className="card-box">
                            <div className="card-header"><div className="title">Header</div></div>
                            <CardBody>
                                <form>
                                    <Row>
                                        <Col lg="4" md="6">
                                            <Row className="form-group">
                                                <label className="col-md-4">First Name:</label>
                                                <Col md="8"><input type="text" name="" className="form-control " /></Col>
                                            </Row>
                                        </Col>
                                        <Col lg="4" md="6">
                                            <Row className="form-group">
                                                <label className="col-md-4">Last Name:</label>
                                                <Col md="8"><input type="text" name="" className="form-control" /></Col>
                                            </Row>
                                        </Col>
                                        <Col lg="4" md="6">
                                            <Row className="form-group">
                                                <label className="col-md-4">Phone:</label>
                                                <Col md="8"><input type="text" name="" className="form-control" /></Col>
                                            </Row>
                                        </Col>
                                        <div className="col-md-12"> 
                                            <div className="float-right">
                                                <button type="button" className="btn-primary-fill mr-2">Submit</button>
                                                <button type="button" className="btn-primary-outline">Clear</button>
                                            </div>
                                        </div>
                                    </Row>
                                    
                                </form>
                            </CardBody>
                        </Card>
                        <Card className="card-box">
                            <div className="card-header"><div className="title">Header</div></div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group row">
                                                <label className="col-md-4">First Name:</label>
                                                <div className="col-md-8"><input type="text" name="" className="form-control " /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group row">
                                                <label className="col-md-4">Last Name:</label>
                                                <div className="col-md-8"><input type="text" name="" className="form-control" /></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group row">
                                                <label className="col-md-4">Phone:</label>
                                                <div className="col-md-8"><input type="text" name="" className="form-control" /></div>
                                            </div>
                                        </div>
                                        <div className="col-md-12"> 
                                            <div className="float-right">
                                                <button type="button" className="btn-primary-fill mr-2">Submit</button>
                                                <button type="button" className="btn-primary-outline">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default Home