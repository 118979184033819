import React, {Component} from 'react'

class UserAdd extends Component{

	render(){
		return(
			<React.Fragment>
                <form>
                	<div className="row">
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">Name</label>
                				<div className="col-md-9">
									<input type="text" className="form-control" />
								</div>
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">Mobile #</label>
								<div className="col-md-9">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">Email</label>
								<div className="col-md-9">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">Address</label>
								<div className="col-md-9">
									<input type="text" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">User Type</label>
								<div className="col-md-9">
									<select className="form-control">
										<option>Delivery Admin</option>
										<option>Delivery Man</option>
										<option>Front Office</option>
										<option>Invoicing Admin</option>
										<option>Super Admin</option>
									</select>
								</div>
                				
                			</div>
                		</div>
                        <div className="col-md-6">
                			<div className="form-group row">
                				<label className="col-md-3">Password</label>
								<div className="col-md-9">
									<input type="password" className="form-control" />
								</div>
                				
                			</div>
                		</div>
                		<div className="col-md-12 mt-4"> 
                            <div className="float-right">
                                <button type="button" className="btn-primary-fill mr-2">Save</button>
                                <button type="button" className="btn-primary-outline" onClick={() => {this.props.updateLayout('User List')}}>Cancel</button>
                            </div>
                        </div>
                	</div>
                </form>
            </React.Fragment>
			)
	}
}

export default UserAdd