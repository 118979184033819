import React, { Component } from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import OrderList from '../components/OrderList'
import OrderCreate from '../components/OrderCreate'

class Orders extends Component{

    constructor(props){
    super(props);

    this.state = {
        hideShow : false
        }
    }

    toggleButton = () =>{
        this.setState(prevState => ({
          hideShow: !prevState.hideShow
        }));
    }

	render(){
		return(
			<div className="container-fluid">
                <Row>
                    <Col md="12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title">{this.state.hideShow ? 'Create Order' : 'Orders List'}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="btn-primary-fill float-right mt-3" onClick={this.toggleButton}> {this.state.hideShow ? 'Orders List' : 'Create Order'}</button>
                                    </div>
                                </div>
                            </div>
                            <CardBody>
                                { this.state.hideShow ? <OrderCreate /> : <OrderList /> }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
			)
	}

}

export default Orders