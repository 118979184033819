import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTranslation } from 'react-i18next';
import ToolkitProvider, { Search, CSVExport  } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
const { ExportCSVButton } = CSVExport;
class InventoryList extends Component {
constructor(props) {
	super(props);
}

buttonFormatter = (cell, row) => {
	console.log(cell, row)
	return (<button type="button" className="btn-primary-outline" onClick={() => this.props.handleOrderView('View Customer Order', row.customerId)}><i className="icon icon-eye"></i></button>);
}

	render() {
        const { SearchBar } = Search;
		const columns = [
			{
				dataField: "material",
				text: 'Material',
			},
			{
				dataField: "totalCollected",
				text: 'Total Collected',
            },
			{
				dataField: "totalUsed",
				text: 'Total Used',
            },
            {
				dataField: "department",
				text: 'Department',
            },
            {
				dataField: "loss",
				text: '%Loss',
            },
            {
				dataField: "date",
				text: 'Date',
            },
		];
		this.inventory = [
            { 
                material: "Alison Alsop", 
                totalCollected: 10,
                totalUsed: 3,
                department: '',
                loss: '3%',
                date: '03/25/2020'
            },
            { 
                material: "Alison Alsop", 
                totalCollected: 10,
                totalUsed: 3,
                department: '',
                loss: '3%',
                date: '03/25/2020'
            },
            { 
                material: "Alison Alsop", 
                totalCollected: 10,
                totalUsed: 3,
                department: '',
                loss: '3%',
                date: '03/25/2020'
            },
            
          ];
		return (
			<React.Fragment>
				<ToolkitProvider
					keyField="customerId"
                    key={`customerTable`}
					data={this.inventory}
					columns={ columns }
					search
				>
                    {
                        props => (
							<div>
								<div className="row inventory-table">
                                    
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Material</label>
                                            <input type="text" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Department</label>
                                            <input type="text" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <input type="Date" className="form-control"/>
                                        </div>
                                    </div>
                                    {/* <div className="offset-md-7 col-md-3">
                                        <SearchBar { ...props.searchProps } />
                                    </div> */}
                                    
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label></label>
                                            <ExportCSVButton className="btn btn-primary-fill btn-sm my-2 float-right mt-4" { ...props.csvProps }>Generate CSV</ExportCSVButton>
                                        </div>
                                    </div>
                                    
                                </div>
								<BootstrapTable { ...props.baseProps } pagination={paginationFactory()}/>
							</div>
                        )
                    }
				</ToolkitProvider>

			</React.Fragment>
		)
	}
}

export default InventoryList;