import React from 'react'
import SimpleLineIcon from 'react-simple-line-icons'
import { BrowserRouter as Router, NavLink, withRouter } from "react-router-dom";
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';

class Sidebar extends React.Component{
	// componentDidMount() {
	// 	let classes = document.getElementById('reportDropdown').className;
	// 	console.log(classes.search('show'), this.props);

	// 	if(this.props.location.pathname.search('reports') != -1) {
	// 		document.getElementById('reportDropdown').classList.add('show')
	// 	}else {

	// 	}
	// }
    render(){
    	//console.log(this.props.handleClass ? "main-layout" : "main-layout open");

        return(
            <div className={this.props.handleClass ? "main-layout" : "main-layout open"} id="side-menu">
				<aside className="left-sidebar-layout ">
					<div className="sidebar-content">
						<ul>
							{/* <li><NavLink exact  to="/main" className="sidebar-menu-item" activeClassName='active'><i className="icon-speedometer icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Dashboard</span></NavLink></li> */}
							<li><NavLink to="/main/customers" className="sidebar-menu-item" activeClassName='active'><i className="icon-user icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Customers</span></NavLink></li>
							<li><NavLink to="/main/orders" className="sidebar-menu-item" activeClassName='active'><i className="icon-basket icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Orders</span></NavLink></li>
							<li><NavLink to="/main/invoice" className="sidebar-menu-item" activeClassName='active'><i className="icon-book-open icon"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Invoices</span></NavLink></li>
						    <li><NavLink to="/main/assignedpackage" className="sidebar-menu-item" activeClassName='active'><i className="icon-present icon"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Package</span></NavLink></li>
                            {/* <li><NavLink to="/main/users" className="sidebar-menu-item" activeClassName='active'><i className="icon-people icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Users</span></NavLink></li>

							<li><NavLink to="/main/assignedpackage" className="sidebar-menu-item" activeClassName='active'><i className="icon-present icon"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Packages</span></NavLink></li>
							{/* <li><NavLink to="/main/users" className="sidebar-menu-item" activeClassName='active'><i className="icon-people icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Users</span></NavLink></li>

							<li><NavLink to="/main/branches" className="sidebar-menu-item" activeClassName='active'><i className="icon-organization icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Branches</span></NavLink></li>
							<li><NavLink to="/main/itemMaster" className="sidebar-menu-item" activeClassName='active'><i className="icon-bag icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Item Master</span></NavLink></li>
							<li>
								<NavLink to="/main/branches" id="toggler" className="sidebar-menu-item sidebar-sub-menu" activeClassName='active'><i className="icon-graph icons"></i> <span className={this.props.handleClass ? "menu hide" : "menu"}>Reports</span></NavLink>
								<UncontrolledCollapse toggler="#toggler" id="reportDropdown">
									<ul className="mt-0">
										<li><NavLink exact  to="/main/reports/inventory" className="sidebar-menu-item sidebar-sub-menu-item" activeClassName='active'> <span className={this.props.handleClass ? "menu hide" : "menu"}>Inventory</span></NavLink></li>
										<li><NavLink to="/main/reports/wastage" className="sidebar-menu-item sidebar-sub-menu-item" activeClassName='active'><span className={this.props.handleClass ? "menu hide" : "menu"}>Wastage</span></NavLink></li>
										<li><NavLink to="/main/reports/sales" className="sidebar-menu-item sidebar-sub-menu-item" activeClassName='active'><span className={this.props.handleClass ? "menu hide" : "menu"}>Sales </span></NavLink></li>
									</ul>
								</UncontrolledCollapse>
							</li> */}
						</ul>
					</div>
				</aside>
			</div>

        )
    }
}
export default withRouter(Sidebar)