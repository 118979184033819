import React, {Component} from 'react'
import {Row, Col, Card, CardBody, Label, Input} from 'reactstrap'
import imgUpload from '../assets/images/img-upload.png'

class OrderCreate extends Component{

    constructor(props){
    super(props);
     this.state = {
            imagePreviewTop: imgUpload,
            imageUrls:[]
        }
        this.fileTop = React.createRef();
    }


    _handleImageChange(e) {
        e.preventDefault();
        let imageName = e.target.getAttribute('name');
         // console.log('fileTop', imageName)
        let file = ''
        if(imageName === 'topImage'){
            
            file = e.target.files[0]
        }
       
        if(file===undefined){
            return false
        }
        let reader = new FileReader();

        reader.onloadend = () => {
             if(imageName === 'topImage'){
             
                const temp = [...this.state.imageUrls]
                temp.push(reader.result)
                this.setState({
                    imageUrls: temp
                })
            } 
        }
        reader.readAsDataURL(file);
       
    }

    closeImage =(e)=>{
        e.target.parentNode.remove();
    }

    pickupType = (e) => {
        if(e.target.value == 0) { 
            document.getElementById('pickupBranch').classList.remove("hide")
            document.getElementById('pickupAddr').classList.add("hide")
        }else{
            document.getElementById('pickupAddr').classList.remove("hide")
            document.getElementById('pickupBranch').classList.add("hide")
        }
    }
    deliveryType = (e) => {
        if(e.target.value == 0) { 
            document.getElementById('deliveryBranch').classList.remove("hide")
            document.getElementById('deliveryAddr').classList.add("hide")
        }else{
            document.getElementById('deliveryAddr').classList.remove("hide")
            document.getElementById('deliveryBranch').classList.add("hide")
        }
    }

	render(){
		return(
            <React.Fragment>
                <form>
                    <Row>
                        <Col lg="6" md="6">
                            <Row className="form-group">
                                <label className="col-md-4">Select Customer:</label>
                                <Col md="8" className="d-flex align-items-center">
                                    <Input type="select" name="item" id="cName">
                                        <option value="0">-- customer name --</option>
                                        <option value="1">Lizzette Mccoll</option>
                                        <option value="2">Eugena Skelley</option>
                                        <option value="3">Ervin Harn</option>
                                        <option value="4">Winifred Jose</option>
                                        <option value="5">Tresa Maske</option>
                                        <option value="6">Delilah Thornley</option>
                                        <option value="7">Fernande Maben</option>
                                    </Input>
                                    <a href="#" className="add-more" data-toggle="modal" data-target="#addCustomer" title="add New Customer"><i className="icon-plus icons"></i></a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6">
                            <Row className="form-group">
                                <label className="col-md-4">Remarks:</label>
                                <Col md="8"><input type="text" name="" className="form-control" /></Col>
                            </Row>
                        </Col>
                        <Col lg="6" md="6">
                            <Row className="form-group">
                                <label className="col-md-4">Quantity:</label>
                                <Col md="8"><input type="text" name="" className="form-control" /></Col>
                            </Row>
                        </Col>
                        <Col lg="6" md="6">
                            <Row className="form-group">
                                <label className="col-md-4">Pickup Type:</label>
                                <Col md="8">
                                    <Input type="select" name="item" id="pickup" onChange={this.pickupType}>
                                        <option >-- pickup type --</option>
                                        <option value="0">Drop Off</option>
                                        <option value="1">Pickup</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row className="form-group hide" id="pickupBranch">
                                <label className="col-md-4">Branch:</label>
                                <Col md="8">
                                    <Input type="select" name="item" id="">
                                        <option >-- select branch --</option>
                                        <option value="0">Branch A</option>
                                        <option value="1">Branch B</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row className="form-group hide" id="pickupAddr">
                                <label className="col-md-4">Address:</label>
                                <Col md="8"><input type="text" name="" className="form-control" /></Col>
                            </Row>
                        </Col>
                        <Col lg="6" md="6">
                            <Row className="form-group">
                                <label className="col-md-4">Delivery Type:</label>
                                <Col md="8">
                                    <Input type="select" name="item" id="delivery" onChange={this.deliveryType}>
                                        <option >-- delivery type --</option>
                                        <option value="0">Branch</option>
                                        <option value="1">Drop Off</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row className="form-group hide" id="deliveryBranch">
                                <label className="col-md-4">Branch:</label>
                                <Col md="8">
                                    <Input type="select" name="item" id="">
                                        <option >-- select branch --</option>
                                        <option value="0">Branch A</option>
                                        <option value="1">Branch B</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row className="form-group hide" id="deliveryAddr">
                                <label className="col-md-4">Address:</label>
                                <Col md="8"><input type="text" name="" className="form-control" /></Col>
                            </Row>
                        </Col>
                        
                    </Row>
                    <Row className="mb-5">
                        <Col lg="2" sm="3" xs="12">
                            <Label htmlFor="item">Upload Images:</Label>
                        </Col>
                        <Col lg="10" sm="9" xs="12">
                            <div className="upload-image">
                                {this.state.imageUrls.length>0 ? this.state.imageUrls.map((item,index)=>{
                                    return(
                                        <div className="imgPreview"  key={index}>
                                            <img src={item} className="img-fluid" />
                                            <i className="icon-close icons close" onClick={(e) =>this.closeImage(e)}></i>
                                        </div>
                                        )
                                }):''}
                                <div className="imgPreview">
                                    <Input type="file" name="topImage" onChange={(e)=>this._handleImageChange(e)}/> 
                                    <img src={imgUpload} className="img-fluid"/>    
                                </div>
                            </div>
                        </Col>
                        <div className="col-md-12 mt-4"> 
                            <div className="float-right">
                                <button type="button" className="btn-primary-fill mr-2">Submit</button>
                                <button type="button" className="btn-primary-outline">Clear</button>
                            </div>
                        </div>
                    </Row>
                </form>
                <div className="modal fade" id="addCustomer" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add Customer</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>
                            <Col lg="12" md="12">
                                <Row className="form-group">
                                    <label className="col-md-4">Name:</label>
                                    <Col md="8"><input type="text" name="" className="form-control " /></Col>
                                </Row>
                            </Col>
                            <Col lg="12" md="12">
                                <Row className="form-group">
                                    <label className="col-md-4">Email:</label>
                                    <Col md="8"><input type="text" name="" className="form-control" /></Col>
                                </Row>
                            </Col>
                            <Col lg="12" md="12">
                                <Row className="form-group">
                                    <label className="col-md-4">Phone:</label>
                                    <Col md="8"><input type="text" name="" className="form-control" /></Col>
                                </Row>
                            </Col>
                            <Col lg="12" md="12">
                                <Row className="form-group">
                                    <label className="col-md-4">Address:</label>
                                    <Col md="8"><input type="text" name="" className="form-control" /></Col>
                                </Row>
                            </Col>
                        </Row>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn-primary-fill mr-2" data-dismiss="modal">Submit</button>
                        <button type="button" className="btn-primary-outline" data-dismiss="modal">Clear</button>
                      </div>
                    </div>
                  </div>
                </div>
            </React.Fragment>
			)
	}
}

export default OrderCreate