import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import BranchAdd from '../components/BranchAdd'
import BranchList from '../components/BranchList'
import ViewCustomerOrder from '../components/ViewCustomerOrder'

class Branches extends Component{

	constructor(props){
    super(props);

    this.state = {
        customerLayout : "Branch List"
        }
    }

    userList = (props) =>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> Branch List</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Create Branch") }}>  Create Branch</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchList handleOrderView={this.updateLayout}/>
                </CardBody>
            </Card>
            )
    }

    customerCreate =(props)=>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> Create Branch</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Branch List") }}>  Branch List</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchAdd updateLayout={this.updateLayout}/>
                </CardBody>
            </Card>
            )
    }

    customerOrderPreview =(props)=>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">Edit Branch</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Branch List") }}>  Branch List</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchAdd updateLayout={this.updateLayout}/>
                </CardBody>
            </Card>
            )
    }

    updateLayout = (layout) =>{
        this.setState({customerLayout : layout});
    }

    getCustomerComponent(layout){

        var showLayout = '';

        switch(layout){
            case "Branch List" : showLayout=this.userList(this.props);
            break;
            case "Create Branch" : showLayout=this.customerCreate(this.props);
            break;
            case "Edit Branch" : showLayout=this.customerOrderPreview(this.props);
            break;
            default:
            showLayout = "";
        }

        return showLayout;

    }

	render(){
		let showLayout = this.getCustomerComponent(this.state.customerLayout)
		return(
			<div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {showLayout}
                    </div>
                </div>
            </div>
			)
	}
}

export default Branches