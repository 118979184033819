import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"

class CustomerList extends Component{

	render(){

		this.columns = [
		    
		    {
		      dataField: "itemId",
		      text: "Item Id"
		    },
		    {
		      dataField: "itemName",
		      text: "Item Name"
		    },
		    {
		    	dataField: "rate",
		    	text: "Rate"
            },
            {
		    	dataField: "action",
		    	text: "Action"
		    }
		];

		this.customer = [
		  { 
            itemId: "#0090", 
            itemName: "Iten One",
            rate: '21',
            action: <React.Fragment>
				<button type="button" className="btn mr-2" onClick={()=>this.props.handleOrderView('Edit Item')}><i className="icon-pencil icon"></i></button>
				<button type="button" className="btn"><i className="icon-trash icon"></i></button>
			</React.Fragment>
		  },
		  { 
            itemId: "#0090", 
            itemName: "Iten One",
            rate: '21',
            action: <React.Fragment>
				<button type="button" className="btn mr-2" onClick={()=>this.props.handleOrderView('Edit Item')}><i className="icon-pencil icon"></i></button>
				<button type="button" className="btn"><i className="icon-trash icon"></i></button>
			</React.Fragment>
		  },
		  { 
            itemId: "#0090", 
            itemName: "Iten One",
            rate: '21',
            action: <React.Fragment>
				<button type="button" className="btn mr-2" onClick={()=>this.props.handleOrderView('Edit Item')}><i className="icon-pencil icon"></i></button>
				<button type="button" className="btn"><i className="icon-trash icon"></i></button>
			</React.Fragment>
		  }
		];

		return(
			<React.Fragment>
				<div className="table-responsive">
                	<BootstrapTable keyField="id" data={this.customer} columns={this.columns} />
                	<Pagination className="float-right">
		                <PaginationItem>
		                  <PaginationLink previous tag="button"></PaginationLink>
		                </PaginationItem>
		                <PaginationItem active>
		                  <PaginationLink tag="button">1</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">2</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">3</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink tag="button">4</PaginationLink>
		                </PaginationItem>
		                <PaginationItem>
		                  <PaginationLink next tag="button"></PaginationLink>
		                </PaginationItem>
		              </Pagination>
                </div>
	           </React.Fragment>
			)
	}
}

export default CustomerList